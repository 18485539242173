var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{attrs:{"title":"Using custom form components","subtitle":"The scope contains attributes and event handlers that can be directly bound to most custom inputs or select components.","modalid":"modal-9","modaltitle":"Using custom form components"},scopedSlots:_vm._u([{key:"highlight",fn:function(){return [_c('pre',{staticClass:"mb-0"},[_vm._v("        "),_c('code',{staticClass:"javascript"},[_vm._v("\n<template>\n  <div>\n    <b-form-tags v-model=\"value\" no-outer-focus class=\"mb-2\">\n      <template v-slot=\"{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }\">\n        <b-input-group class=\"mb-2\">\n          <b-form-input\n            v-bind=\"inputAttrs\"\n            v-on=\"inputHandlers\"\n            placeholder=\"New tag - Press enter to add\"\n            class=\"form-control\"\n          ></b-form-input>\n          <b-input-group-append>\n            <b-button @click=\"addTag()\" variant=\"primary\">Add</b-button>\n          </b-input-group-append>\n        </b-input-group>\n        <div class=\"d-inline-block\" style=\"font-size: 1.5rem;\">\n          <b-form-tag\n            v-for=\"tag in tags\"\n            @remove=\"removeTag(tag)\"\n            :key=\"tag\"\n            :title=\"tag\"\n            :variant=\"tagVariant\"\n            class=\"mr-1\"\n          >"+_vm._s(_vm.tag)+"</b-form-tag>\n        </div>\n      </template>\n    </b-form-tags>\n  </div>\n</template>\n\n<script>\n  export default {\n    data() {\n      return {\n        value: ['apple', 'orange', 'banana']\n      }\n    }\n  }\n</script>\n        ")]),_vm._v("\n      ")])]},proxy:true},{key:"comcode",fn:function(){return [_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var tags = ref.tags;
          var inputAttrs = ref.inputAttrs;
          var inputHandlers = ref.inputHandlers;
          var tagVariant = ref.tagVariant;
          var addTag = ref.addTag;
          var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New tag - Press enter to add"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Add")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.5rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])}),1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }